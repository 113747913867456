





















































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Settings16 from '@carbon/icons-vue/es/settings/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import Add16 from '@carbon/icons-vue/es/add/16'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import axios from 'axios'

import TablePagination from '@/partials/tables/Pagination.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import GenericModalDelete from '@/partials/modals/generic/delete.vue'

@Component({
  components: {
    TablePagination,
    Settings16,
    TrashCan16,
    Edit16,
    Add16,
    GenericModalDelete
  }
})
export default class LibrariesIndex extends Mixins(PermissionsMixin, FlashMessageMixin) {
  deleteModalIsOpened = false
  filteredData = []

  selectedMaterial = {
    id: '',
    title: ''
  }

  data = []

  materialsTotal = 0

  confirmLibraryDelete (library: any) {
    this.selectedMaterial = library
    this.toggleModal('deleteModalIsOpened')
  }

  postSubmit (message: string) {
    this.filterMaterials('', '', 1)
    this.toggleModal('deleteModalIsOpened')

    const flashMessage = {
      message: message,
      isSuccess: true
    }
    this.setFlashMessage(flashMessage)
  }

  onPageChange (pagination: Pagination) {
    this.filterMaterials('', '', pagination.page, pagination.length)
  }

  search (searchString: string) {
    this.filteredData = this.data.filter((material: Record<string, any>) => {
      let { title, type } = material
      title = title.toLowerCase()
      type = type.name.toLowerCase()
      searchString = searchString.toLowerCase()

      if (title.includes(searchString) || type.includes(searchString)) {
        return material
      }
    })
  }

  toggleModal (modalName: string) {
    this.$data[modalName] = !this.$data[modalName]
  }

  typeTabs: Array<TabRoute> = []

  selectedTab = {}

  async created () {
    await axios.get('library/type')
      .then(response => {
        response.data.data.forEach((type: LibraryType) => {
          this.typeTabs.push({ label: type.name, query: type.id })
        })
      })
  }

  @Watch('$route.query.type')
  filterMaterials (_: string, __: string, page = 1, perPage = 20) {
    let route = 'library?'
    const { type } = this.$route.query

    route += type ? `type_id=${type}` : ''

    const perPageSnake = snakeCaseKeys({ perPage })
    axios.get(route, {
      params: {
        'page[number]': page,
        ...perPageSnake
      }
    })
      .then(response => {
        this.filteredData = this.data = camelCaseKeys(response.data.data, { deep: true })
        this.materialsTotal = response.data.total
      })
  }

  tabChange (tab: number) {
    const route = this.typeTabs[tab]
    this.selectedTab = route

    const newRoute = {
      query: {
        type: route.query
      }
    }

    this.switchRoute(newRoute)
  }

  switchRoute (newRoute: Record<string, any>) {
    if (this.$route.name === 'Libraries' && this.$router.resolve(newRoute).href !== this.$route.fullPath) {
      this.$router.push(newRoute)
    }
  }
}
